import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchPosts } from '../../../api/blog';
import Text from '../../../components/Text/Text';
import * as styles from './UpdatesAndBio.styles';
import * as commonStyles from '../../../styles/commonStyles';
import PostCard from '../../../components/PostCard/PostCard';
import { pageContent } from '../../../contents/pageContent';
import Spinner from '../../../components/Spinner/Spinner';
import { useMediaQueries } from '../../../hooks/useMediaQueries';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import { Link } from 'react-router-dom';
import { PATHS } from '../../../routes/paths';

function UpdatesAndBio() {
  const { isPending, error, data } = useQuery({
    queryKey: ['posts', { page: 1, pageSize: 1 }],
    queryFn: () => fetchPosts({ page: 1, pageSize: 1 }),
  });

  const { isMobile } = useMediaQueries();

  return (
    <section css={[commonStyles.container, styles.updatesAndBio]}>
      <div css={styles.postWrapper}>
        <Text variant={isMobile ? 'mHeadline2' : 'dHeadline2'} color="primary" uppercase>
          {pageContent.updatesTitle}
        </Text>

        {isPending && <Spinner />}

        {error && <ErrorMessage />}

        {data?.posts.map((post) => (
          <div css={styles.postCardWrapper} key={post.id}>
            <PostCard post={post} />
          </div>
        ))}
      </div>

      <div css={styles.bioWrapper}>
        <div css={styles.titleWrapper}>
          <Text variant={isMobile ? 'mHeadline2' : 'dHeadline2'} color="primary" uppercase>
            {pageContent.bioTitle}
          </Text>
        </div>

        <div css={styles.bio}>
          <img
            src={require(
              isMobile
                ? '../../../assets/images/bio.jpeg'
                : '../../../assets/images/bio-mobile.jpeg'
            )}
            alt="bio-image"
          />

          <div>
            <Text
              variant={isMobile ? 'body16' : 'body16'}
              color="white"
              withLineBreaks={true}
              align={'justify'}
            >
              {pageContent.bioDescription.substring(0, 450)}
            </Text>
            <p style={{ color: 'white' }}>
              {'... '}
              <Link to={`${PATHS.ABOUT_ME}`}>Czytaj dalej</Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default UpdatesAndBio;
