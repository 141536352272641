import { Transaction } from '../../../api/shop/types';
import styles from './TransactionDetails.module.css';

function TransactionDetails({ transaction }: { transaction: Transaction }) {
  return (
    <div className={styles.transactionDetails}>
      <p className={styles.title}>Podsumowanie</p>

      <p className={styles.details}>Płatność: {transaction.totalAmount}</p>
      <p className={styles.details}>Sposób płatności: {transaction.paymentMethod}</p>
      <p className={styles.details}>Dostawa: {transaction.shipmentMethod}</p>

      <p className={styles.title}>Przedmioty:</p>
      <ul className={styles.itemsList}>
        {transaction.items.map((item, index) => (
          <li key={index} className={styles.item}>
            {item.productName} - Ilość: {item.quantity}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default TransactionDetails;
