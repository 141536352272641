import React, { useEffect } from 'react';
import { container, section } from '../../../styles/commonStyles';
import { ShopConfig } from '../../../api/shop/types';
import { useLocation } from 'react-router-dom';
import * as styles from './CartPayments.styles';
import Text from '../../../components/Text/Text';
import { useCart } from '../../../store/cartSlice/useCart';
import { PATHS } from '../../../routes/paths';
import { fetchTransactionById } from '../../../api/shop';
import { useQuery } from '@tanstack/react-query';
import Spinner from '../../../components/Spinner/Spinner';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import TransactionDetails from './TransactionDetails';

function CartPayments() {
  const { state }: { state: { config: ShopConfig } } = useLocation();
  const { clearCart } = useCart();
  useEffect(() => {
    clearCart();
  }, []);

  const params = new URLSearchParams(window.location.search);
  const paymentStatus = params.get('paymentStatus');
  const transactionId = params.get('transactionId');
  if (paymentStatus === 'CONFIRMED' && transactionId !== undefined) {
    const { isPending, error, data } = useQuery({
      queryKey: ['transaction'],
      queryFn: () => fetchTransactionById(transactionId),
    });

    return (
      <div css={[container, section]}>
        {isPending && <Spinner />}

        {error && <ErrorMessage />}

        <Text variant="dHeadline3" align="center" marginBottom={32}>
          Dziękuje za zakup.
        </Text>
        {data && <TransactionDetails transaction={data} />}
        <Text variant="subtitle16" align="center" marginTop={20}>
          <a href={PATHS.SHOP} rel="noopener noreferrer">
            Powrót do sklepu
          </a>
        </Text>
      </div>
    );
  } else {
    return (
      <div css={[container, section]}>
        <div css={styles.cartPayments}>
          <Text variant="dHeadline3" align="center" marginBottom={32}>
            Dane do przelewu:
          </Text>

          <Text variant="body16">
            Nazwa odbiorcy: <span>{state?.config.bank_transfer_account_name}</span>
          </Text>
          <Text variant="body16">
            Adres: <span>{state?.config.bank_transfer_address}</span>
          </Text>
          <Text variant="body16">
            Numer konta: <span>{state?.config.bank_transfer_account_number}</span>
          </Text>

          <Text variant="body16">
            Przewidywan termin wysyłki: <span>{state?.config.delivery_time}</span>
          </Text>
        </div>
      </div>
    );
  }
}

export default CartPayments;
