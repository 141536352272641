import { css } from '@emotion/react';

export const image = css`
  width: 90%; /* Adjust the maximum width as needed */
`;

export const imageContainer = css`
  margin-right: 20px;
  width: 33%;
`;

export const textContainer = css`
  flex: 1;
`;

export const rowContainer = css`
  display: flex;
`;
