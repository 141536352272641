export const pageContent = {
  updatesTitle: 'Aktualizacje',
  bioTitle: 'O mnie',
  bioDescription:
    'Zazwyczaj lubię pisać i piszę sporo, bo myśli mam więcej niż mieści moja głowa, ale napisać o sobie tak bez woalu......\n' +
    '...... patrzę w kartkę którąś godzinę i nie wiem jak zacząć, a tu trzeba szczerze i z polotem, a raczej finezją 😉\n' +
    'Stwierdziłam więc, że zacznę od tego jak jest , że nie wiem, a potem, to już popłynie, jak zawsze.\n' +
    '\n' +
    'Łatwiej operuje mi się metaforami, obrazami ,historyjkami zaczerpniętymi ze świata przyrody, ale fakt jest taki, że to wszystko to ja , skrywająca się za filtrem odniesień.\n' +
    '\n' +
    'To co tu piszę i to, co tworzę , pochodzi z mojego wnętrza. To jest moje widzenie i rozumienie wszystkiego co we mnie, przy mnie, za mną i wokół mnie. Cały czas przy okazji to wnętrze eksploruje i odkrywam, stąd też czerpię inspiracje, nowe pomysły i opowieści dziwnej treści. \n' +
    '\n' +
    'To co tu widzicie i czytacie to moje życie i cały świat widziany moimi oczami w kilku zdaniach, bywa, że zrymowanych, drewnianych formach skrzydlatych i kolorowych obrazach.\n' +
    'Czasem wesołe i barwne, a czasem bardzo ponure, ale życie przecież jest mozaiką przecinających się linii i splotów wydarzeń, przygód wszelakich, emocji skrajnie różnych.\n' +
    'Niemniej pięknych i ciekawych, nadających barw z niekończącej się palety odczuć.\n' +
    'Staram się być szczera ze sobą i ze światem, więc piszę jak jest, a jak milczę to już bardzo źle 😉\n' +
    '\n' +
    'Moja przygoda z rękodziełem zaczęła się kilka lat temu w wyniku życiowej rewolucji, w skutek czego, między innymi, z księgowej stałam się drewnianym obliczem swojej finezji.\n' +
    'Rewolucja ucichła na szczęście, natomiast twórcze zakochanie trwa nadal, kwitnie, ewoluuje i umacnia mnie w przekonaniu, że wszystko jest dokładnie takie jak być powinno.\n' +
    '\n' +
    'Uczę się każdego dnia\n' +
    'Uczę się nowych technik\n' +
    'Uczę się nowego podejścia do tematów \n' +
    'Uczę się przekraczać granice swojej wyobraźni \n' +
    'Uczę się jak być w czasie ogarniętą , \n' +
    'mniej przejętą\n' +
    'Uczę się odczytywać Wasze potrzeby\n' +
    'Uczę się jak być dobrym twórcą, sprzedawcą, marketingowcem, logistykiem, doradcą\n' +
    'Siebie się uczę w tym wszystkim\n' +
    'I Facebooka, hasztagów, zasięgów, rolek 🙈\n' +
    'Zarządzania zasobami ogólnie \n' +
    'I czasem w tym trochę się gubię, więc uczę się przy okazji, jak wracać na właściwe tory.\n' +
    '\n' +
    'Cenię sobie bardzo spokój , jaki daje mi twórcze zajęcie, ciszę podczas pracy pozornie głośnych maszyn, czas , w którym coś powstaje, a jednocześnie mogę w myślach być każdym i wszędzie oraz swobodę kreowania tego, co we mnie na to co poza mną.\n' +
    'Myślę Anioł, biorę deskę i jest 🙂\n' +
    'Ujrzeć przez ułamek sekundy coś w głowie, a potem to zmaterializować, zobaczyć to oczami, dotknąć dłońmi....\n' +
    'Magia \n' +
    '\n' +
    'Rękodzieło pozwala mi być sobą w stworzonym przeze mnie świecie.\n' +
    'Pozwala mi jednocześnie odlatywać i być tu i teraz ( w księgowości tego nie mieli 😜)\n' +
    'Ta praca to dar i każdego dnia dziękuję, że jestem tu gdzie jestem, że mogę wymyślać i te wymyślunki realizować.\n' +
    'Cieszę się, że dane mi jest dzielić się tym , co uważam, jest całkiem dobre.\n' +
    'Wkładam w swoje prace całe serce i zależy mi byście to również poczuli. \n' +
    'Cząstka mnie jest w każdej z tych rzeczy. Wykonuję je z zaangażowaniem i starannością, byś Dobry Człowieku biorąc w dłonie takiegoż np Anioła, spojrzał na niego i poczuł radosne ciepło.\n' +
    'Odrobina tego uczucia do mnie wraca\n' +
    'I mnie ubogaca 🙂\n' +
    '\n' +
    'Anioły są moje najulubieńsze do tworzenia.\n' +
    'Poniekąd trochę dzięki nim tu dotarłam i w ten sposób się odwdzięczam.\n' +
    'Rozsyłam ich energię dobra i miłości w świat.\n' +
    '\n' +
    'Co do zamówień, to chętnie doradzę, wymyślę, podpowiem. Jestem otwarta na sugestie i Wasze wyobrażenia też biorę pod uwagę 😉\n' +
    '\n' +
    'Część rzeczy jest dostępna od ręki, większość mogę zrobić na zamówienie, a jeśli nie ma tego czego szukasz, zapytaj.\n' +
    'Tu się dzieje magia, więc czemu nie 🙂\n' +
    'Czas realizacji jest różny, a indywidualne projekty wymagają indywidualnej ceny, kwestia dogadania.\n' +
    'Przedmioty na zamówienie mogą nieco się różnić od wzorów pokazanych na zdjęciu.\n' +
    'Nie ma dwóch identycznych desek, a każdą rzecz robię ręcznie, więc każda jedna jest wyjątkowa, \n' +
    'a już moja w tym głowa by było pięknie i z finezją.\n' +
    '\n' +
    'Czas do brzegu\n' +
    'Wiedziałam, że jak zacznę, to nie będę wiedziała kiedy skończyć 🙈\n' +
    '\n' +
    'Zapraszam Cię do swojej rzeki płynącej zmiennym nurtem, gdzie \n' +
    'doceniam wczoraj, cieszę się dzisiaj, \n' +
    'jestem dobrej myśli i robię co mogę, \n' +
    'najlepiej jak potrafię 🙂\n' +
    'Złota myśl: wiem, że nic nie wiem\n' +
    'Za to z zaciekawieniem odkrywam.\n' +
    'Śmiało wskakuj, rozgość się i pozostań jeśli pasuje Ci ten kierunek.\n' +
    'Jesteś tu zawsze mile widziany.\n' +
    '\n' +
    'M.B.',
  offersTitle: 'Oferty',
};
