import React, { ReactElement, CSSProperties, ReactNode } from 'react';
import { typography, FontVariant } from '../../theme/typography';
import { colors } from '../../theme/colors';

interface TextProps {
  variant: FontVariant;
  style?: CSSProperties;
  children?: ReactNode;
  color?: keyof typeof colors;
  lowercase?: boolean;
  uppercase?: boolean;
  marginBottom?: number;
  marginTop?: number;
  marginLeft?: number;
  marginRight?: number;
  align?: 'left' | 'center' | 'right' | 'justify';
  withLineBreaks?: boolean;
  trim?: number;
}

function renderTextWithLineBreaks(text: string) {
  return text
    .replace(/\r\n/g, '\n')
    .replace(/\r\n/g, '\n')
    .split('\n')
    .map((line, index, array) =>
      index === array.length - 1 ? (
        line
      ) : (
        <>
          {line}
          <br />
        </>
      )
    );
}

function Text({
  variant,
  style,
  children,
  color = 'black',
  lowercase,
  uppercase,
  marginBottom,
  marginTop,
  marginLeft,
  marginRight,
  align,
  withLineBreaks = false,
  trim,
}: TextProps): ReactElement {
  const variantConfig = typography.variants[variant];

  if (lowercase && typeof children === 'string') {
    children = children.toLowerCase();
  }

  if (uppercase && typeof children === 'string') {
    children = children.toUpperCase();
  }

  if (!variantConfig) {
    console.warn(`Typography variant "${variant}" not found. Using default.`);
    return <p style={{ ...typography.variants.body16, ...style }}>{children}</p>;
  }

  if (trim && typeof children == 'string') {
    children = children.substring(0, trim);
  }
  if (withLineBreaks && typeof children == 'string') {
    children = renderTextWithLineBreaks(children);
  }

  const { fontFamily, fontWeight, fontSize, lineHeight, ...otherStyles } = variantConfig;

  return (
    <p
      style={{
        fontFamily,
        fontWeight,
        fontSize,
        lineHeight,
        color: colors[color],
        marginBottom: marginBottom ? `${marginBottom}px` : undefined,
        marginTop: marginTop ? `${marginTop}px` : undefined,
        marginLeft: marginLeft ? `${marginLeft}px` : undefined,
        marginRight: marginRight ? `${marginRight}px` : undefined,
        textAlign: align,
        ...style,
        ...otherStyles,
      }}
    >
      {children}
    </p>
  );
}

export default Text;
