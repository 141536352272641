import React from 'react';
import * as styles from './AboutMe.styles';
import { pageContent } from '../../contents/pageContent';
import Text from '../../components/Text/Text';
import { container, homeSection } from '../../styles/commonStyles';

function AboutMe() {
  return (
    <div css={[homeSection, container]}>
      <div css={styles.rowContainer}>
        <div css={styles.imageContainer}>
          <img src={require('../../assets/images/bio.jpeg')} alt="" css={styles.image} />
        </div>
        <div css={styles.textContainer}>
          <Text variant={'body20'} color="primary" withLineBreaks={true}>
            {pageContent.bioDescription}
          </Text>
        </div>
      </div>
    </div>
  );
}

export default AboutMe;
