import React from 'react';
import UpdatesAndBio from './components/UpdatesAndBio';
import Offers from './components/Offers';

function Home() {
  return (
    <>
      <UpdatesAndBio />
      <Offers />
    </>
  );
}

export default Home;
